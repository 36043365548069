import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Grid from "@mui/material/Unstable_Grid2";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import QRCode from "react-qr-code";
import ChangePassword from "../../components/user/changePassword";
import VerifyMfa from "../../components/user/verifyMfa";

function CustomTabPanel(props) {
  const { children, selectedTab, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={selectedTab !== index}
      id={`simple-tab-panel-${index}`}
      {...other}
    >
      {selectedTab === index && (
        <Box sx={{ p: 2 }}>
          {children}
        </Box>
      )}
    </div>
  )
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  selectedTab: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Clients() {
  const [setSnackbar, loginState, setLoginState, auth, userState, setUserState, user, setUser] = useOutletContext();
  const [selectedTab, setSelectedTab] = useState(0);
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [mfa, setMfa] = useState(false);
  const [key, setKey] = useState('');
  const [code, setCode] = useState('');
  const [firstName, setFirstName] = useState((user && user.given_name) || '');
  const [lastName, setLastName] = useState((user && user.family_name) || '');
  const [userProcessing, setUserProcessing] = useState(false);

  function handleTabChange(event, newSelectedTab) {
    if (user.mfaEnabled) {
      setMfa(user.mfaEnabled);
    }
    setSelectedTab(newSelectedTab);
  }

  async function handleMfaChange(event) {
    setMfa(event.target.checked);
    if (mfa) {
      let newUser = user;
      newUser.mfaEnabled = false;
      newUser.authenticator = '';
      setUser(newUser);
      await auth.disableMfa();
    } else {
      const result = await auth.getSoftwareToken();
      if (result) {
        setKey(result);
      }
    }
  }

  function handleEnableMfa() {
    auth.enableMfa(code).then((result) => {
      if (result) {
        let newUser = user;
        newUser.mfaEnabled = true;
        newUser.authenticator = 'Authenticator';
        setUser(newUser);
        setSnackbar('success', 'Your authenticator app was succesfully paired.');
      } else {
        setSnackbar('error', 'An error occurred wile attempting to verify your authenticator app.');
      }
    });
  }

  function handlePasswordChange() {
    auth.changePassword(password, newPassword).then((result) => {
      if (result) {
        setPassword('');
        setNewPassword('');
        setSnackbar('success', 'Your password was successfully changed.');
      } else {
        setSnackbar('error', 'An error occurred while attempting to change your password.');
      }
    });
  }

  function handleResetUserInfo() {
    setFirstName((user && user.given_name) || '');
    setLastName((user && user.family_name) || '');
  }

  async function handleUpdateUserInfo() {
    setUserProcessing(true);
    try {
      const newUser = await auth.updateUserInfo({
        given_name: firstName,
        family_name: lastName,
      });
      if (newUser.email) {
        setSnackbar('success', 'Your profile information has been successfully updated.');
        setUser(newUser);
      } else {
        setSnackbar('error', 'An error occurred while attempting to update your profile.');
      }
    } catch (err) {
      setSnackbar('error', 'An error occurred while attempting to update your profile.');
    } finally {
      setUserProcessing(false);
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant='h3' component="h3">
        Your Profile
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="basic tabs example">
          <Tab label="Profile" {...a11yProps(0)} />
          <Tab label="Security" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel selectedTab={selectedTab} index={0}>
        <Grid container spacing={4}>
          <Grid xs>
            <TextField
              fullWidth
              label="First Name"
              variant="standard"
              value={firstName}
              onChange={(event) => {
                setFirstName(event.target.value);
              }}
            />
          </Grid>
          <Grid xs>
            <TextField
              fullWidth
              label="Last Name"
              variant="standard"
              value={lastName}
              onChange={(event) => {
                setLastName(event.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid xs>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="flex-end"
            >
              <Button
                disabled={userProcessing || null}
                variant="outlined"
                color="error"
                onClick={handleResetUserInfo}
              >
                Cancel
              </Button>
              <Button
                disabled={userProcessing || null}
                sx={{ml: 1}}
                variant="contained"
                onClick={handleUpdateUserInfo}
              >
                Update
              </Button>
            </Box>
          </Grid>
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel selectedTab={selectedTab} index={1}>
        <Grid container spacing={2}>
          <Grid xs>
            <Typography variant="subtitle1" component="span">
              Enable Multifactor Authentication:
            </Typography>
          </Grid>
          <Grid xs={2}>
            <Switch
              checked={mfa}
              onChange={handleMfaChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </Grid>
          {mfa && !user.authenticator && (
          <Grid xs={8}>
            <Typography variant="body1" component="p">
              Please enter the following key into your authenticator app or use the QR code. Once you have created the new item, enter the corresponding verification code in the field below.
            </Typography>
            <Typography variant="subtitle1" sx={{ pt: 2 }} component="p">
              Authenticator Key: {key}
            </Typography>
            <QRCode value={`otpauth://totp/InnerArmor:${user.email}?secret=${key}&issuer=InnerArmor`} size={128} />
            <VerifyMfa code={code} setCode={setCode} handleMfaVerify={handleEnableMfa} />
          </Grid>
          )}
          {mfa && user.authenticator && (
          <Grid xs={8}>
            <Typography variant="subtitle1" component="span">
              The app is paired with {user.authenticator}.
            </Typography>
          </Grid>
          )}
          {!mfa && (
            <Grid xs={8} />
          )}
        </Grid>
        <Grid container spacing={2}>
          <Grid xs sx={{ pt: 3 }}>
            <Typography variant="subtitle1" component="span">
              Change Password:
            </Typography>
          </Grid>
          <Grid xs={10}>
            <ChangePassword
              password={password}
              setPassword={setPassword}
              newPassword={newPassword}
              setNewPassword={setNewPassword}
              handlePasswordChange={handlePasswordChange}
            />
          </Grid>
        </Grid>
      </CustomTabPanel>
    </Box>
  );
}
