import { useOutletContext, Navigate, useLocation } from "react-router-dom";

export default function ProtectedRoute({ children }) {
  const [setSnackbar, isLoggedIn] = useOutletContext();
  const location = useLocation();

  if (!isLoggedIn) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return children;
}
