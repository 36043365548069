import { Link as RouterLink } from "react-router-dom";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from '@mui/icons-material/HomeOutlined';
import UserIcon from '@mui/icons-material/ManageAccountsOutlined';
import GroupIcon from '@mui/icons-material/GroupOutlined';

export default function MainMenu({ isLoggedIn, primaryGroup }) {
  return (
    <>
      <nav aria-label="main">
        <List>
          <ListItemButton component={RouterLink} to="/">
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
          {isLoggedIn && primaryGroup === 'Admin' && (
            <>
              <ListItemButton component={RouterLink} to="/users">
                <ListItemIcon>
                  <UserIcon />
                </ListItemIcon>
                <ListItemText primary="Users" />
              </ListItemButton>
              <ListItemButton component={RouterLink} to="/groups">
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary="Groups" />
              </ListItemButton>
            </>
          )}
        </List>
      </nav>
    </>
  );
}
