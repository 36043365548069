import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Login from "../../components/user/login";
import ChangePassword from "../../components/user/changePassword";
import VerifyResetCode from "../../components/user/verifyResetCode";
import VerifyMfa from "../../components/user/verifyMfa";
import { useOutletContext, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

export default function LoginView() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConf, setPasswordConf] = useState('');
  const [code, setCode] = useState('');
  const [authenticator, setAuthenticator] = useState('');
  const [setSnackbar, isLoggedIn, setLoginState, auth, userState, setUserState, user, setUser, setPrimaryGroup] = useOutletContext();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isLoggedIn) {
      const origin = location.state?.from?.pathname || '/';
      navigate(origin)
    }
  });

  async function handleLoginResponse(userState, primaryGroup, authenticator) {
    setUserState(userState);
    if (userState === 'Authenticated') {
      setPrimaryGroup(primaryGroup);
      updateUserInfo();
      setSnackbar('success', 'You have successfully logged in.');
      setLoginState(true);
      const origin = location.state?.from?.pathname || '/';
      navigate(origin);
    } else {
      if (userState === 'New Password Required') {
        setSnackbar('warning', 'You must set a new password to continue.');
      } else if (userState === 'Password Reset Required') {
        setSnackbar('warning', 'You must reset your password to continue.');
      } else if (userState === 'MFA Code Required') {
        setAuthenticator(authenticator);
        setSnackbar('warning', 'You must enter your verification code to complete the login process.');
      } else {
        setSnackbar('error', 'An error occurred while attempting to log you in.');
      }
      setLoginState(false);
    }
  }

  function handleLogin() {
    auth.login(
      {
        username: email,
        password: password,
      },
      handleLoginResponse
    );
  }

  async function updateUserInfo() {
    const result = await auth.getUserInfo();
    if (authenticator) {
      setUser({...result.userInfo, ...{mfaEnabled: true, authenticator: authenticator}})
    } else {
      setUser(result.userInfo);
    }
  }

  async function handleNewPasswordResponse(userState, primaryGroup) {
    setUserState(userState);
    if (userState === 'Authenticated') {
      setPrimaryGroup(primaryGroup);
      updateUserInfo();
      setSnackbar('success', 'You have successfully logged in and your new password has been saved.');
      setLoginState(true);
    } else {
      setSnackbar('error', 'An error occurred while attempting to set your new password.');
      setLoginState(false);
    }
  }

  function handlePasswordSet() {
    auth.handleNewPassword(passwordConf, handleNewPasswordResponse);
  }

  function handleConfirmPassword() {
    auth.confirmPassword(email, code, password).then((result) => {
      if (result) {
        setUserState('Unauthenticated');
        setSnackbar('success', 'Your password has been successfully reset. Please log back in.');
        navigate('/login');
      } else {
        setSnackbar('error', 'An error occurred while attempting to reset your password.');
      }
    });
  }

  async function handleMfaChallengeResponse(userState, primaryGroup) {
    setUserState(userState);
    if (userState === 'Authenticated') {
      setPrimaryGroup(primaryGroup);
      updateUserInfo();
      setUser(user);
      setSnackbar('success', 'You have successfully logged in.');
      setLoginState(true);
    } else {
      setSnackbar('error', 'An error occurred while attempting to verify your code.');
      setLoginState(false);
    }
  }

  function handleMfaVerify() {
    auth.handleMfaChallenge(code, handleMfaChallengeResponse);
  }

  return (
    <Box>
      <Typography variant='h4' component="h4">
        Login
      </Typography>
      {userState === 'Unauthenticated' && (
        <>
          <Typography variant='body1' component="p">
            Please enter your username and password below to login.
          </Typography>
          <Login
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            handleLogin={handleLogin}
          />
        </>
      )}
      {userState === 'New Password Required' && (
        <>
          <Typography variant='body1' component="p">
            You must set a new password. Please enter it below.
          </Typography>
          <ChangePassword
            password={password}
            setPassword={setPassword}
            newPassword={passwordConf}
            setNewPassword={setPasswordConf}
            handlePasswordChange={handlePasswordSet}
          />
        </>
      )}
      {userState === 'Password Reset Required' && (
        <>
          <Typography variant='body1' component="p">
          You have been sent a password reset code. Please enter that code and your new password below.
          </Typography>
          <VerifyResetCode
            email={email}
            setEmail={setEmail}
            code={code}
            setCode={setCode}
            password={password}
            setPassword={setPassword}
            handlePasswordReset={handleConfirmPassword}
          />
        </>
      )}
      {userState === 'MFA Code Required' && (
        <>
          <Typography variant='body1' component="p">
            Your account has MFA enabled, please enter your authenticator code below.
          </Typography>
          <VerifyMfa
            code={code}
            setCode={setCode}
            handleMfaVerify={handleMfaVerify}
          />
        </>
      )}
    </Box>
  );
}
