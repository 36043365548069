import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ResetPasswordIcon from "@mui/icons-material/LockReset";
import DisableIcon from "@mui/icons-material/DesktopAccessDisabled";
import EnableIcon from "@mui/icons-material/DesktopWindows";
import EnabledIcon from "@mui/icons-material/CheckCircle";
import DisabledIcon from "@mui/icons-material/Cancel";

export default function userRow({
  user,
  processing,
  handleEdit,
  handleResetPassword,
  handleDisable,
  handleEnable,
  handleDelete,
  locale,
  timeZone,
  sx
}) {
  function handleEditClick() {
    handleEdit(user);
  }

  function handleResetPasswordClick() {
    handleResetPassword(user.id);
  }

  function handleDisableClick() {
    handleDisable(user.id);
  }

  function handleEnableClick() {
    handleEnable(user.id);
  }

  function handleDeleteClick() {
    handleDelete(user.id);
  }

  const createdDate = new Date(user.createdDate);
  const displayDate = createdDate.toLocaleDateString(locale, {timeZone: timeZone});
  const displayTime = createdDate.toLocaleTimeString(locale, {timeZone: timeZone});
  
  return (
    <TableRow sx={sx}>
      <TableCell>
        <Link href={`/user/${user.id}`}>
          {`${user.firstName} ${user.lastName}`}
        </Link>
      </TableCell>
      <TableCell>
        <Link href={`mailto:${user.email}`}>
          {user.email}
        </Link>
      </TableCell>
      <TableCell>
        {`${user.focusId ? user.focusId : ''}`}
      </TableCell>
      <TableCell>
        {`${user.divergenceId ? user.divergenceId : ''}`}
      </TableCell>
      <TableCell>
        {`${user.visionId ? user.visionId : ''}`}
      </TableCell>
      <TableCell>
        {`${displayDate} ${displayTime}`}
      </TableCell>
      <TableCell>
        {user.status}
      </TableCell>
      <TableCell>
        {user.enabled ? (
          <EnabledIcon color="primary" fontSize="small" />
        ) : (
          <DisabledIcon color="error" fontSize="small" />
        )}
      </TableCell>
      <TableCell>
        <Stack direction="row" spacing={0}>
          <Tooltip title="Edit">
            <IconButton
              onClick={handleEditClick}
              disabled={processing || null}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Reset Password">
            <IconButton
              onClick={handleResetPasswordClick}
              disabled={processing || null}
            >
              <ResetPasswordIcon />
            </IconButton>
          </Tooltip>
          {user.enabled ? (
            <Tooltip title="Disable">
              <IconButton
                onClick={handleDisableClick}
                disabled={processing || null}
              >
                <DisableIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <>
              <Tooltip title="Enable">
                <IconButton
                  onClick={handleEnableClick}
                  disabled={processing || null}
                >
                  <EnableIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  onClick={handleDeleteClick}
                  disabled={processing || null}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Stack>
      </TableCell>
    </TableRow>
  );
};
