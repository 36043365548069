import { useMemo } from "react";
import { getGroups } from "../requests.mjs";
import useResult from "../response";

export default function useGetGroups(auth, params=null) {
  const request = useMemo(function() {
    return getGroups(auth, params);
  }, [params]);
  return useResult(request);
};
