import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Grid from '@mui/material/Unstable_Grid2';

export default function starsCard({
  stars,
  neuro,
  resilience,
  processing,
  handleEdit,
  sx
}) {
  function handleEditClick() {
    handleEdit();
  }

  return (
    <Card sx={sx}>
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <Tooltip title="Edit">
          <IconButton
            onClick={handleEditClick}
            disabled={processing || null}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      </CardActions>
      <CardContent>
        <Grid container spacing={2}>
          { resilience && (
            <>
              <Grid xs={6} sm={6} lg={4}>
                <Typography variant="h6" color="primary">
                  Power Shaping
                </Typography>
                <Typography variant="subtitle1">
                  {`seated: ${stars.powerShaping.seated}`}
                </Typography>
                <Typography variant="subtitle1">
                  {`standing: ${stars.powerShaping.standing}`}
                </Typography>
                <Typography variant="h6" color="primary">
                  Power Refining
                </Typography>
                <Typography variant="subtitle1">
                  {`seated: ${stars.powerRefining.seated}`}
                </Typography>
                <Typography variant="subtitle1">
                  {`standing: ${stars.powerRefining.standing}`}
                </Typography>
                <Typography variant="h6" color="primary">
                  Power Sharpening
                </Typography>
                <Typography variant="subtitle1">
                  {`seated: ${stars.powerSharpening.seated}`}
                </Typography>
                <Typography variant="subtitle1">
                  {`standing: ${stars.powerSharpening.standing}`}
                </Typography>
              </Grid>
              <Grid xs={6} sm={6} lg={4}>
                <Typography variant="h6" color="primary">
                  Focus Shaping
                </Typography>
                <Typography variant="subtitle1">
                  {`seated: ${stars.focusShaping.seated}`}
                </Typography>
                <Typography variant="subtitle1">
                  {`standing: ${stars.focusShaping.standing}`}
                </Typography>
                <Typography variant="h6" color="primary">
                  Focus Refining
                </Typography>
                <Typography variant="subtitle1">
                  {`seated: ${stars.focusRefining.seated}`}
                </Typography>
                <Typography variant="subtitle1">
                  {`standing: ${stars.focusRefining.standing}`}
                </Typography>
                <Typography variant="h6" color="primary">
                  Focus Sharpening
                </Typography>
                <Typography variant="subtitle1">
                  {`seated: ${stars.focusSharpening.seated}`}
                </Typography>
                <Typography variant="subtitle1">
                  {`standing: ${stars.focusSharpening.standing}`}
                </Typography>
              </Grid>
            </>
          )}
          { neuro && (
            <Grid xs={6} sm={6} lg={4}>
              <Typography variant="h6" color="primary">
                Neuro Shaping
              </Typography>
              <Typography variant="subtitle1">
                {`Cz: ${stars.CzShaping?.count || 0}`}
              </Typography>
              <Typography variant="subtitle1">
                {`T3: ${stars.T3Shaping?.count || 0}`}
              </Typography>
              <Typography variant="subtitle1">
                {`T4: ${stars.T4Shaping?.count || 0}`}
              </Typography>
              <Typography variant="h6" color="primary">
                Neuro Refining
              </Typography>
              <Typography variant="subtitle1">
                {`Cz: ${stars.CzRefining?.count || 0}`}
              </Typography>
              <Typography variant="subtitle1">
                {`T3: ${stars.T3Refining?.count || 0}`}
              </Typography>
              <Typography variant="subtitle1">
                {`T4: ${stars.T4Refining?.count || 0}`}
              </Typography>
              <Typography variant="h6" color="primary">
                Neuro Sharpening
              </Typography>
              <Typography variant="subtitle1">
                {`Cz: ${stars.CzSharpening?.count || 0}`}
              </Typography>
              <Typography variant="subtitle1">
                {`T3: ${stars.T3Sharpening?.count || 0}`}
              </Typography>
              <Typography variant="subtitle1">
                {`T4: ${stars.T4Sharpening?.count || 0}`}
              </Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};
