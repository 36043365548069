import { Link as RouterLink } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import MainMenu from "./mainmenu";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import LogOutIcon from "@mui/icons-material/LogoutOutlined";
import LogInIcon from "@mui/icons-material/LoginOutlined";
import SettingsIcon from "@mui/icons-material/Settings";

export default function MenuBar({ state, toggleMenu, isLoggedIn, logOut }) {
  return (
    <Drawer onClick={toggleMenu} anchor="left" open={state}>
      <MainMenu isLoggedIn={isLoggedIn} />
      <Divider />
      <nav aria-label="user">
        {isLoggedIn ? (
          <List>
            <ListItemButton component={RouterLink} to="/profile">
              <ListItemAvatar>
                <Avatar />
              </ListItemAvatar>
              <ListItemText primary="Profile" />
            </ListItemButton>
            <ListItemButton component={RouterLink} to="/settings">
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItemButton>
            <ListItemButton onClick={logOut}>
              <ListItemIcon>
                <LogOutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </List>
        ) : (
          <List>
            <ListItemButton component={RouterLink} to="/login">
              <ListItemIcon>
                <LogInIcon />
              </ListItemIcon>
              <ListItemText primary="Login" />
            </ListItemButton>
          </List>
        )}
      </nav>
    </Drawer>
  );
}
