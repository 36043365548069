import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function Home() {
  return (
    <Box>
      <Typography variant='h3' component="h3">
        Welcome to the Inner Armor - Training Web Portal
      </Typography>
    </Box>
  );
}
