import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export default function timeZoneSelect({timeZone, setTimeZone}) {
  const timeZones = Intl.supportedValuesOf('timeZone');

  function handleChange(event) {
    setTimeZone(event.target.value);
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="time-zone-selector-label">Time Zone</InputLabel>
      <Select
        labelId="time-zone-selector-label"
        id="time-zone-selector"
        value={timeZone === null ? '' : timeZone}
        label="Time Zone"
        onChange={handleChange}
      >
        {timeZones.map((timeZone) => {
          return <MenuItem key={timeZone} value={timeZone}>{timeZone}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
};
