import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import RequestPasswordReset from "../../components/user/requestPasswordReset";
import VerifyResetCode from "../../components/user/verifyResetCode";
import { useOutletContext, useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

export default function ForgotPasswordView() {
  const [codeRequestState, setCodeRequestState] = useState(false);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [setSnackbar, isLoggedIn, setLoginState, auth] = useOutletContext();
  const navigate = useNavigate();
  const location = useLocation();

  function handlePasswordReset() {
    auth.forgotPassword(email).then((result) => {
      if (result) {
        setSnackbar('success', 'A verification code has been sent to your email.');
        setCodeRequestState(true);
      } else {
        setSnackbar('error', 'An error occurred while attempting to ')
        setCodeRequestState(false);
      }
    });
  }

  function handleConfirmPassword() {
    auth.confirmPassword(email, code, password).then((result) => {
      if (result) {
        setSnackbar('success', 'Your password has been successfully reset. Please log back in.');
        navigate('/login');
      } else {
        setSnackbar('error', 'An error occurred while attempting to reset your password.');
      }
    });
  }

  useEffect(() => {
    if (isLoggedIn) {
      const origin = location.state?.from?.pathname || '/';
      navigate(origin)
    }
  });

  return (
    <Box>
      <Typography variant='h4' component="h4">
        Forgot Password
      </Typography>
      {!codeRequestState && (
        <>
          <Typography variant='body1' component="p">
            Please enter the email address below that is associated with your account.
          </Typography>
          <RequestPasswordReset
            email={email}
            setEmail={setEmail}
            handlePasswordReset={handlePasswordReset}
          />
        </>
      )}
      {codeRequestState && (
        <>
          <Typography variant='body1' component="p">
          We have emailed you a verification code. Please enter that code and your new password below.
          </Typography>
          <VerifyResetCode
            email={email}
            setEmail={setEmail}
            code={code}
            setCode={setCode}
            password={password}
            setPassword={setPassword}
            handlePasswordReset={handleConfirmPassword}
          />
        </>
      )}
    </Box>
  );
}
