import { Route } from "react-router-dom";
import ProtectedRoute from "./protected";
import { RequestProvider } from "../apis/innerArmorTraining/v1/requestContainer";
import Login from "../views/user/Login";
import ForgotPassword from "../views/user/ForgotPassword";
import Profile from "../views/user/Profile";
import Users from "../views/user/Users";
import Groups from "../views/user/Groups";
import User from "../views/user/User";
import Group from "../views/user/Group";

export default function routes() {
  return (
    <>
      <Route path="login" element={<Login />} />
      <Route path="forgotPassword" element={<ForgotPassword />} />
      <Route path="profile" element={
        <ProtectedRoute>
          <Profile />
        </ProtectedRoute>
      } />
      <Route path="users" element={
        <ProtectedRoute>
          <RequestProvider>
            <Users />
          </RequestProvider>
        </ProtectedRoute>
      } />
      <Route path="groups" element={
        <ProtectedRoute>
          <RequestProvider>
            <Groups />
          </RequestProvider>
        </ProtectedRoute>
      } />
      <Route path="user/:userId" element={
        <ProtectedRoute>
          <RequestProvider>
            <User />
          </RequestProvider>
        </ProtectedRoute>
      } />
      <Route path="group/:groupName" element={
        <ProtectedRoute>
          <RequestProvider>
            <Group />
          </RequestProvider>
        </ProtectedRoute>
      } />
    </>
  );
};
