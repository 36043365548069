import { Route } from "react-router-dom";
import App from "../App";
import ErrorPage from "../error-page";
import userRoutes from "./user";
import settingsRoutes from "./settings";

export default function routes() {
  return (
    <>
      <Route exact path="/" element={<App />}>
        {userRoutes()}
        {settingsRoutes()}
        <Route path="*" element={<ErrorPage />} />
      </Route>
    </>
  );
}
