import axios from "axios";

export const BASE_URL = process.env.REACT_APP_INNERARMOR_V1_API_URL;

function createUrl(base, path) {
  return `${base}${path}`;
}

function createAuthHeader(token) {
  return {
    authorization: `Bearer ${token}`,
  };
}

//request structure for each of the endpoints in this API
export function createUser(auth, user) {
  return [
    createUrl(BASE_URL, '/user'),
    {
      method: 'post',
    },
    user,
    auth
  ];
}

export function getUsers(auth, params=null) {
  return [
    createUrl(BASE_URL, '/users'),
    {
      method: 'get',
      params: params || null,
    },
    null,
    auth
  ];
}

export function getUser(auth, userId) {
  return [
    createUrl(BASE_URL, `/user/${userId}`),
    {
      method: 'get',
    },
    null,
    auth
  ];
}

export function updateUser(auth, user) {
  return [
    createUrl(BASE_URL, `/user/${user.userId}`),
    {
      method: 'patch',
    },
    user,
    auth
  ];
}

export function disableUser(auth, userId) {
  return [
    createUrl(BASE_URL, `/user/${userId}/disable`),
    {
      method: 'patch',
    },
    null,
    auth
  ];
}

export function enableUser(auth, userId) {
  return [
    createUrl(BASE_URL, `/user/${userId}/enable`),
    {
      method: 'patch',
    },
    null,
    auth
  ];
}

export function resetPassword(auth, userId) {
  return [
    createUrl(BASE_URL, `/user/${userId}/resetPassword`),
    {
      method: 'patch',
    },
    null,
    auth
  ];
}

export function deleteUser(auth, userId) {
  return [
    createUrl(BASE_URL, `/user/${userId}`),
    {
      method: 'delete',
    },
    null,
    auth
  ];
}

export function getTags(auth, params=null) {
  return [
    createUrl(BASE_URL, '/tags'),
    {
      method: 'get',
      params: params || null,
    },
    null,
    auth
  ];
}

export function createTag(auth, tag) {
  return [
    createUrl(BASE_URL, '/tag'),
    {
      method: 'post',
    },
    tag,
    auth
  ];
}

export function getStars(auth, userId) {
  return [
    createUrl(BASE_URL, `/user/${userId}/stars`),
    {
      method: 'get',
    },
    null,
    auth
  ];
}

export function updateStars(auth, userId, stars) {
  return [
    createUrl(BASE_URL, `/user/${userId}/stars`),
    {
      method: 'put',
    },
    stars,
    auth
  ];
}

export function getGroups(auth, params=null) {
  return [
    createUrl(BASE_URL, `/groups`),
    {
      method: 'get',
      params: params,
    },
    null,
    auth
  ];
}

export function createGroup(auth, group) {
  return [
    createUrl(BASE_URL, '/group'),
    {
      method: 'post',
    },
    group,
    auth
  ];
}

export function getGroup(auth, groupName) {
  return [
    createUrl(BASE_URL, `/group/${groupName}`),
    {
      method: 'get',
    },
    null,
    auth
  ];
}

export function updateGroup(auth, group) {
  return [
    createUrl(BASE_URL, `/group/${group.id}`),
    {
      method: 'patch',
    },
    group,
    auth
  ];
}

export function deleteGroup(auth, groupName) {
  return [
    createUrl(BASE_URL, `/group/${groupName}`),
    {
      method: 'delete',
    },
    null,
    auth
  ];
}

export default async function request([url, options, data, auth]) {
  try {
    let token = null;
    if (auth) {
      token = await auth.getAccessToken();
    }
    for (const attribute in data) {
      if (data[attribute] === '') {
        data[attribute] = null;
      }
    }
    return (await axios.request({
      url: url,
      method: options.method || 'get',
      ...{
        headers: options.headers || null,
      },
      ...{
        headers: token && createAuthHeader(token),
      },
      params: options.params || null,
      data: data,
      responseType: options.responseType || 'json',
    })).data;
  } catch(err) {
    if (err.response) {
      throw {
        error: err.response.data,
        statusCode: err.response.status,
      };
    } else if (err.request) {
      throw {
        error: 'The request was made but no response was received.',
        statusCode: 408,
      };
    } else {
      throw {
        error: err.message,
        statusCode: 0,
      };
    }
  }
};
