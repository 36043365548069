import { useOutletContext } from "react-router-dom";
import { useState, useEffect } from "react";
import useGetUsers from "../../apis/innerArmorTraining/v1/user/getUsers";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import SearchIcon from "@mui/icons-material/SearchOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableBody from "@mui/material/TableBody";
import UserRow from "../../components/user/row";
import UserEdit from "../../components/user/edit";
import UserCreate from "../../components/user/create";
import request, {
  updateUser,
  createUser,
  disableUser,
  enableUser,
  resetPassword,
  deleteUser
} from "../../apis/innerArmorTraining/v1/requests.mjs";
import UserGroupSelect from "../../components/common/userGroupSelect";
import useGetGroups from "../../apis/innerArmorTraining/v1/user/getGroups";

export default function Users() {
  const [setSnackbar, loginState, setLoginState, auth] = useOutletContext();
  const [loading, setLoading] = useState(true);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    locale: 'en-US',
    timeZone: 'America/Toronto',
    focusId: '',
    groups: [],
  });
  const [processing, setProcessing] = useState(false);
  const [params, setParams] = useState({});
  const [users, error, refreshUsers] = useGetUsers(auth, params);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [supportedGroups] = useGetGroups(auth);
  const [groups, setGroups] = useState([]);

  function handleUserCreate() {
    setUser({
      email: '',
      firstName: '',
      lastName: '',
      locale: 'en-US',
      timeZone: 'America/Toronto',
      focusId: '',
      resilience: true,
      neuro: false,
      divergenceId: null,
      groups: [{
        name: 'User'
      }],
    });
    setCreateDialogOpen(true);
  }

  function handleUserEdit(user) {
    setUser(user);
    setEditDialogOpen(true);
  }

  function handleDialogClose() {
    setCreateDialogOpen(false);
    setEditDialogOpen(false);
  }

  async function handleCreateUser(user) {
    setProcessing(true);
    try {
      await request(createUser(auth, user));
      setCreateDialogOpen(false);
      refreshUsers();
      setSnackbar('success', 'The user was created successfully. They have been sent an invitation email.');
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to create the user.');
    }
    setProcessing(false);
  }

  async function handleUpdateUser(user) {
    setProcessing(true);
    try {
      await request(updateUser(auth, user));
      setEditDialogOpen(false);
      refreshUsers();
      setSnackbar('success', 'The user information was updated successfully.');
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to update the user information.');
    }
    setProcessing(false);
  }

  async function handleUserDisable(id) {
    setProcessing(true);
    try {
      await request(disableUser(auth, id));
      refreshUsers();
      setSnackbar('success', 'The user was disabled successfully.');
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to disable the user.');
    }
    setProcessing(false);
  }

  async function handleUserEnable(id) {
    setProcessing(true);
    try {
      await request(enableUser(auth, id));
      refreshUsers();
      setSnackbar('success', 'The user was enabled successfully.');
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to disable the user.');
    }
    setProcessing(false);
  }

  async function handleUserResetPassword(id) {
    setProcessing(true);
    try {
      await request(resetPassword(auth, id));
      refreshUsers();
      setSnackbar('success', 'The user\'s password was reset successfully. They have been sent an email.');
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to reset the user\'s password.');
    }
    setProcessing(false);
  }

  async function handleUserDelete(id) {
    setProcessing(true);
    try {
      await request(deleteUser(auth, id));
      refreshUsers();
      setSnackbar('success', 'The user was deleted successfully.');
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to delete the user.');
    }
    setProcessing(false);
  }

  function handleFilter() {
    setLoading(true);
    try {
      setParams({
        ...params,
        ...{
          searchTerm: searchTerm,
          groups: groups.length > 0 ? groups.join(',') : undefined,
          startFrom: 0,
        },
      });
      setPage(0);
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to retrieve the users.');
    }
    setLoading(false);
  }

  useEffect(() => {
    if (error) {
      setSnackbar('error', error.error);
    }
    setLoading(false);
  }, [users, error]);

  const handlePageChange = (event, value) => {
    setLoading(true);
    setParams({
      ...params,
      ...{ startFrom: value*10 }
    });
    setPage(value);
  };

  const handleLimitChange = (event) => {
    setLoading(true);
    const rowsPerPage = parseInt(event.target.value);
    setRowsPerPage(rowsPerPage);
    setParams({
      ...params,
      ...{
        limit: rowsPerPage,
        startFrom: 0,
      },
    });
    setPage(0);
  };

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Typography
          variant='h3'
          component="h3"
          sx={{ mb: 2 }}
        >
          Users
        </Typography>
        <IconButton
          size="large"
          color="primary"
          onClick={handleUserCreate}
        >
          <AddIcon />
        </IconButton>
      </Stack>
      <Stack direction="row" spacing={2}>
        <TextField
          id="filled-search"
          label="Search Users"
          type="search"
          variant="filled"
          value={searchTerm}
          onChange={event => setSearchTerm(event.target.value)}
        />
        <UserGroupSelect
          groups={groups}
          setGroups={setGroups}
          supportedGroups={supportedGroups || []}
        />
        <IconButton
          size="large"
          color="primary"
          onClick={handleFilter}
        >
          <SearchIcon />
        </IconButton>
      </Stack>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table aria-label="Users">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Focus ID</TableCell>
              <TableCell>Divergence ID</TableCell>
              <TableCell>Vision ID</TableCell>
              <TableCell
                sortDirection="desc"
              >
                <TableSortLabel
                  active={true}
                  direction="desc"
                >
                  Created Date
                </TableSortLabel>
              </TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Enabled</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading && users?.users && users.users.map((user) => {
              return (
                <UserRow
                  key={user.id}
                  user={user}
                  processing={processing}
                  handleEdit={handleUserEdit}
                  handleDisable={handleUserDisable}
                  handleEnable={handleUserEnable}
                  handleResetPassword={handleUserResetPassword}
                  handleDelete={handleUserDelete}
                  sx={{ mb: 2 }}
                />
              );
            })}
            {loading && (
              <TableRow>
                <TableCell colSpan={7}>
                  <CircularProgress color="primary" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={users?.totalUsers || 0}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        size="medium"
        color="primary"
      />
      <Dialog
        open={editDialogOpen}
        onClose={handleDialogClose}
        fullWidth
      >
        <DialogTitle>User Information</DialogTitle>
        <DialogContent>
          <UserEdit
            user={user}
            processing={processing}
            handleUpdate={handleUpdateUser}
            handleCancel={handleDialogClose}
            auth={auth}
            setSnackbar={setSnackbar}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={createDialogOpen}
        onClose={handleDialogClose}
        fullWidth
      >
        <DialogTitle>Create New User</DialogTitle>
        <DialogContent>
          <UserCreate
            user={user}
            processing={processing}
            handleCreate={handleCreateUser}
            handleCancel={handleDialogClose}
            auth={auth}
            setSnackbar={setSnackbar}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
