import { useOutletContext } from "react-router-dom";
import { useState, useEffect } from "react";
import useGetGroups from "../../apis/innerArmorTraining/v1/user/getGroups";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import SearchIcon from "@mui/icons-material/SearchOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TablePagination from "@mui/material/TablePagination";
import TextField from "@mui/material/TextField";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import GroupRow from "../../components/user/group/row";
import GroupEdit from "../../components/user/group/edit";
import GroupCreate from "../../components/user/group/create";
import request, {
  updateGroup,
  createGroup,
  deleteGroup,
} from "../../apis/innerArmorTraining/v1/requests.mjs";

export default function Users() {
  const [setSnackbar, loginState, setLoginState, auth] = useOutletContext();
  const [loading, setLoading] = useState(true);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [group, setGroup] = useState({
    name: '',
    description: '',
  });
  const [processing, setProcessing] = useState(false);
  const [params, setParams] = useState({
    paginate: true,
  });
  const [groups, error, refreshGroups] = useGetGroups(auth, params);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');

  function handleGroupCreate() {
    setGroup({
      name: '',
      description: '',
    });
    setCreateDialogOpen(true);
  }

  function handleGroupEdit(group) {
    setGroup(group);
    setEditDialogOpen(true);
  }

  function handleDialogClose() {
    setCreateDialogOpen(false);
    setEditDialogOpen(false);
  }

  async function handleCreateGroup(group) {
    setProcessing(true);
    try {
      await request(createGroup(auth, group));
      setCreateDialogOpen(false);
      refreshGroups();
      setSnackbar('success', 'The user group was created successfully.');
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to create the user group.');
    }
    setProcessing(false);
  }

  async function handleUpdateGroup(group) {
    setProcessing(true);
    try {
      await request(updateGroup(auth, group));
      setEditDialogOpen(false);
      refreshGroups();
      setSnackbar('success', 'The user group information was updated successfully.');
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to update the user group information.');
    }
    setProcessing(false);
  }

  async function handleGroupDelete(groupName) {
    setProcessing(true);
    try {
      await request(deleteGroup(auth, groupName));
      refreshGroups();
      setSnackbar('success', 'The user group was deleted successfully.');
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to delete the user group.');
    }
    setProcessing(false);
  }

  function handleFilter() {
    setLoading(true);
    try {
      setParams({
        ...params,
        ...{
          searchTerm: searchTerm,
          startFrom: 0,
        },
      });
      setPage(0);
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to retrieve the user groups.');
    }
    setLoading(false);
  }

  useEffect(() => {
    if (error) {
      setSnackbar('error', error.error);
    }
    setLoading(false);
  }, [groups, error]);

  const handlePageChange = (event, value) => {
    setLoading(true);
    setParams({
      ...params,
      ...{ startFrom: value*10 }
    });
    setPage(value);
  };

  const handleLimitChange = (event) => {
    setLoading(true);
    const rowsPerPage = parseInt(event.target.value);
    setRowsPerPage(rowsPerPage);
    setParams({
      ...params,
      ...{
        limit: rowsPerPage,
        startFrom: 0,
      },
    });
    setPage(0);
  };

  return (
    <>
      <Stack direction="row" spacing={2}>
        <Typography
          variant='h3'
          component="h3"
          sx={{ mb: 2 }}
        >
          User Groups
        </Typography>
        <IconButton
          size="large"
          color="primary"
          onClick={handleGroupCreate}
        >
          <AddIcon />
        </IconButton>
      </Stack>
      <Stack direction="row" spacing={2}>
        <TextField
          id="filled-search"
          label="Search Groups"
          type="search"
          variant="filled"
          value={searchTerm}
          onChange={event => setSearchTerm(event.target.value)}
        />
        <IconButton
          size="large"
          color="primary"
          onClick={handleFilter}
        >
          <SearchIcon />
        </IconButton>
      </Stack>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table aria-label="Users">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loading && groups?.groups && groups.groups.map((group) => {
              return (
                <GroupRow
                  key={group.id}
                  group={group}
                  processing={processing}
                  handleEdit={handleGroupEdit}
                  handleDelete={handleGroupDelete}
                  sx={{ mb: 2 }}
                />
              );
            })}
            {loading && (
              <TableRow>
                <TableCell colSpan={7}>
                  <CircularProgress color="primary" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={groups?.totalGroups || 0}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        size="medium"
        color="primary"
      />
      <Dialog
        open={editDialogOpen}
        onClose={handleDialogClose}
        fullWidth
      >
        <DialogTitle>Group Information</DialogTitle>
        <DialogContent>
          <GroupEdit
            group={group}
            processing={processing}
            handleUpdate={handleUpdateGroup}
            handleCancel={handleDialogClose}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={createDialogOpen}
        onClose={handleDialogClose}
        fullWidth
      >
        <DialogTitle>Create New Group</DialogTitle>
        <DialogContent>
          <GroupCreate
            group={group}
            processing={processing}
            handleCreate={handleCreateGroup}
            handleCancel={handleDialogClose}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
