import { useMemo } from "react";
import { getStars } from "../requests.mjs";
import useResult from "../response";

export default function useGetStars(auth, userId) {
  const request = useMemo(function() {
    return getStars(auth, userId);
  }, []);
  return useResult(request);
};
