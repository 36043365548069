import { useMemo } from "react";
import { getUser } from "../requests.mjs";
import useResult from "../response";

export default function useGetUser(auth, userId) {
  const request = useMemo(function() {
    return getUser(auth, userId);
  }, []);
  return useResult(request);
};
