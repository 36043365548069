import { Route } from "react-router-dom";
import ProtectedRoute from "./protected";
import Settings from "../views/settings/Settings";

export default function routes() {
  return (
    <Route path="settings" element={
      <ProtectedRoute>
        <Settings />
      </ProtectedRoute>
    } loader={loader} />
  );
}

export function loader() {
  return {};
}
