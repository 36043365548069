import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Link from "@mui/material/Link";

export default function groupRow({
  group,
  processing,
  handleEdit,
  handleDelete,
  sx
}) {
  function handleEditClick() {
    handleEdit(group);
  }

  function handleDeleteClick() {
    handleDelete(group.id);
  }

  return (
    <TableRow sx={sx}>
      <TableCell>
        <Link href={`/group/${group.id}`}>
          { group.name }
        </Link>
      </TableCell>
      <TableCell>
        { group.description }
      </TableCell>
      <TableCell>
        <Stack direction="row" spacing={0}>
          <Tooltip title="Edit">
            <IconButton
              onClick={handleEditClick}
              disabled={processing || null}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              onClick={handleDeleteClick}
              disabled={processing || null}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </TableCell>
    </TableRow>
  );
};
