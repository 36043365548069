import styles from "./css/styles.css";
import GlobalStyles from '@mui/material/GlobalStyles';
import { createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material';
import Header from "./components/header";
import Grid from '@mui/material/Unstable_Grid2';
import { useState } from "react";
import SideBar from "./components/sidebar";
import MenuBar from "./components/menubar";
import { Outlet, useOutlet, useNavigate } from "react-router-dom";
import Home from "./views/Home";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Footer from "./components/footer";
import auth from "./common/auth";

const inputGlobalStyles = <GlobalStyles styles={styles} />;

const theme = createTheme({
  palette: {
    primary: {
      light: '#FF622E',
      main: '#E65525',
      dark: '#BE431A',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#979797',
      main: '#3C3C3C',
      dark: '#1C1C1C',
      contrastText: '#FFFFFF',
    },
  },
});

export default function App() {
  const [menuState, setMenuState] = useState(false);
  const [userState, setUserState] = useState('Unauthenticated');
  const [loginState, setLoginState] = useState(false);
  const [user, setUser] = useState(null);
  const [primaryGroup, setPrimaryGroup] = useState(null);
  const [snackbarState, setSnackbarState] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState('error');
  const [snackbarMessage, setSnackbarMessage] = useState('An error has occurred');
  const navigate = useNavigate();
  const outlet = useOutlet();

  function toggleMenu() {
    setMenuState(!menuState);
  }

  function setSnackbar(type, message) {
    setSnackbarSeverity(type);
    if (typeof message === 'string') {
      setSnackbarMessage(message);
    } else {
      setSnackbarMessage('An unknown error has occurred.');
    }
    setSnackbarState(true);
  }

  function logOut() {
    auth.logout();
    setUserState('Unauthenticated');
    setUser(null);
    setSnackbar('success', 'You have been successfully logged out.');
    setLoginState(false);
    navigate('/');
  }

  function closeSnackbar() {
    setSnackbarState(false);
  }

  if (!loginState) {
    auth.isUserSignedIn().then((result) => {
      if (result) {
        auth.getUserInfo().then((result) => {
          if (result) {
            setUser({...user, ...result.userInfo});
            setPrimaryGroup(result.primaryGroup);
          }
        });
        setLoginState(true);
      }
    });
  }

  return (
    <>
      <CssBaseline />
      {inputGlobalStyles}
      <ThemeProvider theme={theme}>
        <Header toggleMenu={toggleMenu} isLoggedIn={loginState} logOut={logOut} user={user} />
        <MenuBar
          state={menuState}
          toggleMenu={toggleMenu}
          isLoggedIn={loginState}
          logOut={logOut}
        />
        <Grid maxWidth="100%" container spacing={2}>
          <Grid
            xs={'auto'}
            sm={2}
            mt={1}
            pt={0}
            sx={{
              display: { xs: 'none', sm: 'block' },
              minHeight: { xs: '0', sm: '500px' },
              borderRightStyle: 'solid',
              borderRightColor: '#000000',
              borderRightWidth: '1px',
            }}
          >
            <SideBar isLoggedIn={loginState} primaryGroup={primaryGroup} />
          </Grid>
          <Grid xs p={4}>
            {outlet ? (
              <Outlet context={[setSnackbar, loginState, setLoginState, auth, userState, setUserState, user, setUser, setPrimaryGroup]} />
            ) : (
              <Home />
            )}
          </Grid>
        </Grid>
        <Snackbar open={snackbarState} autoHideDuration={6000} onClose={closeSnackbar}>
          <Alert onClose={closeSnackbar} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <Footer />
      </ThemeProvider>
    </>
  );
}
