import { useState } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import validator from "../../../common/validations.mjs";
import GroupEdit from "./edit";

export default function createGroup({ group, processing, handleCreate, handleCancel }) {
  const [name, setName] = useState(group.name);
  const [nameError, setNameError] = useState(false);

  function validate(group) {
    let valid = true;
    if (!validator().isName(name)) {
      setNameError(true);
      valid = false;
    } else {
      setNameError(false);
    }
    if (valid) {
      handleCreate({
        name: name,
        description: group.description,
      });
    }
  }

  function resetForm() {
    setName(group.name);
    if (handleCancel) {
      handleCancel();
    }
  }

  return (
    <Box>
      <div>
        <FormControl
          fullWidth
          margin="normal"
          variant="outlined"
          error={nameError}
        >
          <InputLabel htmlFor="outlined-name">
            Name
          </InputLabel>
          <OutlinedInput
            id="outlined-name"
            type="text"
            value={name}
            label="Name"
            onChange={event => {
              setName(event.target.value);
            }}
          />
          <FormHelperText>
            {nameError && 'This name is invalid.'}
          </FormHelperText>
        </FormControl>
      </div>
      <GroupEdit
        group={group}
        processing={processing}
        handleUpdate={validate}
        handleCancel={resetForm}
      />
    </Box>
  )
}
