import { useState } from "react";
import useGetGroups from "../../apis/innerArmorTraining/v1/user/getGroups";
import useGetTags from "../../apis/innerArmorTraining/v1/tags/getTags";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import LocaleSelect from "../common/localeSelect";
import TimeZoneSelect from "../common/timeZoneSelect";
import UserGroupSelect from "../common/userGroupSelect";
import InputTags from "../common/inputTags";
import validator from "../../common/validations.mjs";
import request, { createTag } from "../../apis/innerArmorTraining/v1/requests.mjs";

export default function editUser({ user, processing, handleUpdate, handleCancel, auth, setSnackbar }) {
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [locale, setLocale] = useState(user.locale);
  const [timeZone, setTimeZone] = useState(user.timeZone);
  const [focusId, setFocusId] = useState(user.focusId);
  const [divergenceId, setDivergenceId] = useState(user.divergenceId);
  const [visionId, setVisionId] = useState(user.visionId);
  const [resilienceAssessment, setResilienceAssessment] = useState(user.resilience?.assessment || false);
  const [resilienceTraining, setResilienceTraining] = useState(user.resilience?.training || false);
  const [neuroAssessment, setNeuroAssessment] = useState(user.neuro?.assessment || false);
  const [neuroTraining, setNeuroTraining] = useState(user.neuro?.training || false);
  const [groups, setGroups] = useState(getUserGroupNames(user.groups));
  const [tags, setTags] = useState(user.tags || []);
  const [tagParams, setTagParams] = useState({});
  const [groupOptions, groupOptionsError, refreshGroupOptions] = useGetGroups(auth);
  const [tagOptions, tagOptionsError, refreshTagOptions] = useGetTags(auth, tagParams);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [groupsError, setGroupsError] = useState(false);

  function getUserGroupNames(groups) {
    return groups.map((group) => {
      return group.id ?? group.name;
    });
  }

  function getTagValues(tags) {
    return tags.map((tag) => {
      return tag.value;
    });
  }

  async function handleNewTag(value) {
    try {
      if (!tagOptions.includes(value)) {
        await request(createTag(auth, { value: value }));
      }
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to add the tag.');
    }
    refreshTagOptions();
  }

  function validate() {
    let valid = true;
    if (!validator().isName(firstName)) {
      setFirstNameError(true);
      valid = false;
    } else {
      setFirstNameError(false);
    }
    if (!validator().isName(lastName)) {
      setLastNameError(true);
      valid = false;
    } else {
      setLastNameError(false);
    }
    if (groups.length === 0) {
      setGroupsError(true);
      valid = false;
    } else {
      setGroupsError(false);
    }
    if (valid) {
      handleUpdate({
        userId: user.id,
        firstName: firstName,
        lastName: lastName,
        locale: locale,
        timeZone: timeZone,
        focusId: focusId,
        resilience: {
          assessment: resilienceAssessment,
          training: resilienceTraining,
        },
        neuro: {
          assessment: neuroAssessment,
          training: neuroTraining,
        },
        divergenceId: divergenceId,
        visionId: visionId,
        groups: groups,
        tags: tags.map(tag => {
          if (typeof tag === 'string') {
            return tagOptions.find(({value}) => value === tag).id;
          }
          return tag.id;
        }),
      });
    }
  }

  function resetForm() {
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setLocale(user.locale);
    setTimeZone(user.timeZone);
    setFocusId(user.focusId);
    setDivergenceId(user.divergenceId);
    setVisionId(user.visionId);
    setResilienceAssessment(user.resilience?.assessment || false);
    setResilienceTraining(user.resilience?.training || false);
    setNeuroAssessment(user.neuro?.assessment || false);
    setNeuroTraining(user.neuro?.training || false);
    setGroups(getUserGroupNames(user.groups));
    setTags(user.tags || []);
    if (handleCancel) {
      handleCancel();
    }
  }

  return (
    <Box component="form" noValidate>
      <div>
        <FormControl
          fullWidth
          margin="normal"
          variant="outlined"
          error={firstNameError}
        >
          <InputLabel htmlFor="outlined-first-name">
            First Name
          </InputLabel>
          <OutlinedInput
            id="outlined-first-name"
            type="text"
            value={firstName}
            label="First Name"
            onChange={event => {
              setFirstName(event.target.value);
            }}
          />
          <FormHelperText>
            {firstNameError && 'This name is invalid.'}
          </FormHelperText>
        </FormControl>
      </div>
      <div>
        <FormControl
          fullWidth
          margin="normal"
          variant="outlined"
          error={lastNameError}
        >
          <InputLabel htmlFor="outlined-last-name">
            Last Name
          </InputLabel>
          <OutlinedInput
            id="outlined-last-name"
            type="text"
            value={lastName}
            label="Last Name"
            onChange={event => {
              setLastName(event.target.value);
            }}
          />
          <FormHelperText>
            {lastNameError && 'This name is invalid.'}
          </FormHelperText>
        </FormControl>
      </div>
      <div>
        <FormControl
          fullWidth
          margin="normal"
          variant="outlined"
        >
          <InputTags
            tags={tags.map(tag => {
              if (typeof tag === 'string') {
                return tag;
              }
              return tag.value;
            })}
            setTags={setTags}
            options={tagOptions ? getTagValues(tagOptions) : []}
            handleNewTag={handleNewTag}
          />
        </FormControl>
      </div>
      <div>
        <FormControl
          fullWidth
          margin="normal"
          variant="outlined"
        >
          <InputLabel htmlFor="outlined-focus-id">
            Focus ID
          </InputLabel>
          <OutlinedInput
            id="outlined-focus-id"
            type="text"
            value={focusId}
            label="Focus ID"
            onChange={event => {
              setFocusId(event.target.value);
            }}
          />
        </FormControl>
      </div>
      <div>
        <FormControl
          fullWidth
          margin="normal"
          variant="outlined"
        >
          <InputLabel htmlFor="outlined-divergence-id">
            Divergence ID
          </InputLabel>
          <OutlinedInput
            id="outlined-divergence-id"
            type="text"
            value={divergenceId}
            label="Divergence ID"
            onChange={event => {
              setDivergenceId(event.target.value);
            }}
          />
        </FormControl>
      </div>
      <div>
        <FormControl
          fullWidth
          margin="normal"
          variant="outlined"
        >
          <InputLabel htmlFor="outlined-vision-id">
            Vision ID
          </InputLabel>
          <OutlinedInput
            id="outlined-vision-id"
            type="text"
            value={visionId}
            label="Vision ID"
            onChange={event => {
              setVisionId(event.target.value);
            }}
          />
        </FormControl>
      </div>
      <div>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={resilienceAssessment}
              onChange={() => {setResilienceAssessment(!resilienceAssessment)}}
            />
          }
          label="Resilience Assessment?"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={resilienceTraining}
              onChange={() => {setResilienceTraining(!resilienceTraining)}}
            />
          }
          label="Resilience Training?"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={neuroAssessment}
              onChange={() => {setNeuroAssessment(!neuroAssessment)}}
            />
          }
          label="Neuro Assessment?"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={neuroTraining}
              onChange={() => {setNeuroTraining(!neuroTraining)}}
            />
          }
          label="Neuro Training?"
        />
      </FormGroup>
      </div>
      <div>
        <FormControl
          fullWidth
          margin="normal"
          variant="outlined"
        >
          <UserGroupSelect
            groups={groups}
            setGroups={setGroups}
            groupsError={groupsError}
            supportedGroups={groupOptions || []}
          />
        </FormControl>
      </div>
      <div>
        <FormControl
          fullWidth
          margin="normal"
          variant="outlined"
        >
          <LocaleSelect
            locale={locale}
            setLocale={setLocale}
          />
        </FormControl>
      </div>
      <div>
        <FormControl
          fullWidth
          margin="normal"
          variant="outlined"
        >
          <TimeZoneSelect
            timeZone={timeZone}
            setTimeZone={setTimeZone}
          />
        </FormControl>
      </div>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <Button
          disabled={processing || null}
          variant="outlined"
          color="error"
          onClick={resetForm}
        >
          Cancel
        </Button>
        <Button
          disabled={processing || null}
          sx={{ml: 1}}
          variant="contained"
          onClick={validate}
        >
          Save
        </Button>
      </Box>
    </Box>
  )
}
