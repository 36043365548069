import { useContext, createContext } from "react";
import request from "./requests.mjs";

export const RequestContext = createContext();

export function useRequest() {
  return useContext(RequestContext);
};

export function RequestProvider({ children }) {
  return (
    <RequestContext.Provider value={request}>
      {children}
    </RequestContext.Provider>
  );
};
