import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';

const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
});

let user = userPool.getCurrentUser();
let userState = "Initialized";
let tokens = {};
let session = false;
getSession().then((response) => {
  if (response != false) {
    session = response;
    tokens.accessToken = session.getAccessToken().getJwtToken();
    tokens.idToken = session.getIdToken().getJwtToken(); 
  }
});

function getPrimaryGroup(decodedToken) {
  let primaryGroup = 'Therapist';
  if (decodedToken && decodedToken['cognito:groups']) {
    decodedToken['cognito:groups'].map((group) => {
      if (group === 'Admin') {
        primaryGroup = 'Admin';
      }
    });
  }
  return primaryGroup;
}

function authenticate(object, callback) {
  if (object.username && object.password) {
    const authenticationDetails = new AuthenticationDetails({
      Username: object.username,
      Password: object.password,
    });
    user = new CognitoUser({
      Username: object.username,
      Pool: userPool,
    });
    user.authenticateUser(authenticationDetails, {
      onSuccess: function(result) {
        userState = "Authenticated";
        session = result;
        const accessToken = result.getAccessToken().getJwtToken();
        const idToken = result.getIdToken().getJwtToken();
        tokens = {
          accessToken: accessToken,
          idToken: idToken,
        };
        const decodedToken = result.getIdToken().decodePayload();
        const primaryGroup = getPrimaryGroup(decodedToken);
        callback(userState, primaryGroup);
      },
      onFailure: function(err) {
        if (err.name === 'PasswordResetRequiredException') {
          userState = "Password Reset Required";
        } else {
          userState = "Unauthenticated";
        }
        session = false;
        tokens = {};
        callback(userState, null);
      },
      newPasswordRequired: function() {
        userState = "New Password Required";
        session = false;
        tokens = {};
        callback(userState, null);
      },
      totpRequired: function(challengeName, challengeParameters) {
        userState = "MFA Code Required";
        session = false;
        tokens = {};
        challengeParameters;
        callback(userState, null, challengeParameters.FRIENDLY_DEVICE_NAME);
      },
    });
  }
}

function completeNewPasswordChallenge(password, callback) {
  user.completeNewPasswordChallenge(password, [], {
    onSuccess: function(result) {
      userState = "Authenticated";
        const accessToken = result.getAccessToken().getJwtToken();
        const idToken = result.getIdToken().getJwtToken();
        const decodedToken = result.getIdToken().decodePayload();
        const primaryGroup = getPrimaryGroup(decodedToken);
        tokens = {
          accessToken: accessToken,
          idToken: idToken,
        };
        
        callback(userState, primaryGroup);
    },
    onFailure: function(err) {
      userState = "Unauthenticated";
      tokens = {};
      callback(userState, null);
    },
  });
}

function completeMfaChallenge(code, callback) {
  user.sendMFACode(code, {
    onSuccess: function(result) {
      userState = "Authenticated";
        const accessToken = result.getAccessToken().getJwtToken();
        const idToken = result.getIdToken().getJwtToken();
        const decodedToken = result.getIdToken().decodePayload();
        const primaryGroup = getPrimaryGroup(decodedToken);
        tokens = {
          accessToken: accessToken,
          idToken: idToken,
        };
        callback(userState, primaryGroup);
    },
    onFailure: function(err) {
      userState = "Unauthenticated";
      tokens = {};
      callback(userState, null);
    },
  }, 'SOFTWARE_TOKEN_MFA');
}

function getSession() {
  return new Promise((resolve, reject) => {
    if (user) {
      user.getSession(function(err, result) {
        if (err) {
          reject(false);
        }
        resolve(result);
      })
    } else {
      resolve(false);
    }
  });
}

function getUserInfo() {
  return new Promise((resolve, reject) => {
    user.getUserAttributes(function(err, result) {
      if (err) {
        reject(err);
      }
      let userInfo = {};
      for (let index = 0; index < result.length; index++) {
        const element = result[index];
        userInfo[element.Name] = element.Value;
      }
      userInfo.full_name = userInfo.given_name;
      if (userInfo.middle_name) {
        userInfo.full_name = userInfo.full_name + ' ' + userInfo.middle_name;
      }
      userInfo.full_name = userInfo.full_name + ' ' + userInfo.family_name;
      resolve(userInfo);
    });
  });
}

async function checkAndRefreshToken() {
  session = await getSession();
  if (!session) {
    user = false;
  } else {
    const access_token = session.getAccessToken();
    tokens.accessToken = access_token.getJwtToken();
    if (access_token.getExpiration() < Date.now() / 1000) {
      const refresh_token = session.getRefreshToken();
      await new Promise((resolve, reject) => {
        user.refreshSession(refresh_token, (err, result) => {
          if (err) {
            user = false;
            resolve(false);
          } else {
            session = result;
            tokens.accessToken = result.getAccessToken().getJwtToken();
            tokens.idToken = result.getIdToken().getJwtToken();
            resolve(true);
          }
        });
      });
    }
  }
}

function changePassword(oldPassword, newPassword) {
  return new Promise((resolve, reject) => {
    user.changePassword(oldPassword, newPassword, (err, result) => {
      if (!err && result && result === 'SUCCESS') {
        resolve(true);
      }
      resolve(false);
    })
  });
}

function forgotPassword(email, callback) {
  const user = new CognitoUser({
    Username: email,
    Pool: userPool,
  });
  user.forgotPassword({
    onSuccess: function() {
      callback(true);
    },
    onFailure: function(err) {
      console.log(err);
      callback(false);
    },
  });
}

function confirmPassword(email, verificationCode, password, callback) {
  const user = new CognitoUser({
    Username: email,
    Pool: userPool,
  });
  user.confirmPassword(verificationCode, password, {
    onSuccess: function() {
      callback(true);
    },
    onFailure: function(err) {
      console.log(err);
      callback(false);
    },
  });
}

function getSoftwareToken(callback) {
  user.associateSoftwareToken({
    associateSecretCode: function(code) {
      callback(code);
    },
    onFailure: function(err) {
      console.log(err);
      callback(false);
    }
  });
}

function enableMfa(answer, callback) {
  user.verifySoftwareToken(answer, 'Authenticator', {
    onSuccess: function(result) {
      user.setUserMfaPreference(null, {PreferredMfa: true, Enabled: true}, function(err, result) {
        if (!err && result && result === 'SUCCESS') {
          callback(true);
        } else {
          callback(false);
        }
      });
    },
    onFailure: function(err) {
      console.log(err);
      callback(false);
    }
  });
}

function disableMfa(callback) {
  user.setUserMfaPreference(null, {PreferredMfa: false, Enabled: false}, function(err, result) {
    if (!err && result && result === 'SUCCESS') {
      callback(true);
    } else {
      callback(false);
    }
  });
}

export default {
  user: user,
  userPool: userPool,
  login(object, callback) {
    authenticate(object, callback);
  },
  handleNewPassword(password, callback) {
    completeNewPasswordChallenge(password, callback);
  },
  handleMfaChallenge(code, callback) {
    completeMfaChallenge(code, callback);
  },
  isUserSignedIn() {
    return new Promise(async (resolve, reject) => {
      try {
        await checkAndRefreshToken();
      } catch {
        resolve(false);
      }
      resolve(!!user);
    });
  },
  logout() {
    userState = "Unauthenticated";
    if (user != null) {
      user.signOut();
    }
    user = false;
  },
  getUserInfo() {
    return new Promise(async (resolve, reject) => {
      try {
        const userInfo = await getUserInfo();
        const decodedToken = session.getIdToken().decodePayload();
        const primaryGroup = getPrimaryGroup(decodedToken);
        resolve({userInfo, primaryGroup});
      } catch(err) {
        console.log(err);
        resolve({});
      }
    });
  },
  updateUserInfo(attributes) {
    return new Promise((resolve, reject) => {
      let payload = [];
      for (const attribute in attributes) {
        payload.push({
          Name: attribute,
          Value: attributes[attribute],
        });
      }
      user.updateAttributes(payload, async function(err, result) {
        if (err) {
          reject(err);
        }
        if (result === 'SUCCESS') {
          try {
            const userInfo = await getUserInfo();
            resolve(userInfo);
          } catch(err) {
            console.log(err);
            resolve({});
          }
        }
      });
    });
  },
  getAccessToken() {
    return new Promise(async (resolve, reject) => {
      try {
        await checkAndRefreshToken();
      } catch {
        resolve(null);
      }
      if (process.env.NODE_ENV === 'development' || process.env.BUILD_MODE === 'development') {
        console.log(tokens);
      }
      resolve(tokens.accessToken || null);
    });
  },
  getIdToken() {
    return new Promise(async (resolve, reject) => {
      try {
        await checkAndRefreshToken();
      } catch {
        resolve(null);
      }
      resolve(tokens.idToken || null);
    });
  },
  changePassword(oldPassword, newPassword) {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await changePassword(oldPassword, newPassword);
        resolve(result);
      } catch {
        resolve(false);
      }
    });
  },
  forgotPassword(email) {
    return new Promise((resolve, reject) => {
      try {
        forgotPassword(email, (result) => {
          resolve(result);
        });
      } catch {
        resolve(false);
      }
    });
  },
  confirmPassword(email, verificationCode, password) {
    return new Promise((resolve, reject) => {
      confirmPassword(email, verificationCode, password, (result) => {
        resolve(result);
      });
    });
  },
  getSoftwareToken() {
    return new Promise((resolve, reject) => {
      getSoftwareToken((result) => {
        resolve(result);
      });
    });
  },
  enableMfa(answer) {
    return new Promise((resolve, reject) => {
      enableMfa(answer, (result) => {
        resolve(result);
      });
    });
  },
  disableMfa() {
    return new Promise((resolve, reject) => {
      disableMfa((result) => {
        resolve(result);
      });
    });
  }
}
