import { supportedLocales } from "../../common/constants.mjs";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export default function localeSelect({locale, setLocale}) {
  const locales = Intl.getCanonicalLocales(supportedLocales);

  function handleChange(event) {
    setLocale(event.target.value);
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="locale-selector-label">Locale</InputLabel>
      <Select
        labelId="locale-selector-label"
        id="locale-selector"
        value={locale === null ? '' : locale}
        label="Locale"
        onChange={handleChange}
      >
        {locales.map((locale) => {
          return <MenuItem key={locale} value={locale}>{locale}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
};
