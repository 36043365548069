import { useOutletContext, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useGetUser from '../../apis/innerArmorTraining/v1/user/getUser';
import useGetStars from '../../apis/innerArmorTraining/v1/stars/getStars';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/AddCircleOutline';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import StarsCard from '../../components/stars/card';
import StarsEdit from '../../components/stars/edit';
import request, { updateStars } from "../../apis/innerArmorTraining/v1/requests.mjs";

export default function User() {
  const { userId } = useParams();
  const [setSnackbar, loginState, setLoginState, auth] =
    useOutletContext();
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [user, userError, refreshUser] = useGetUser(auth, userId);
  const [stars, starsError, refreshStars] = useGetStars(auth, userId);
  const [editStars, setEditStars] = useState({});

  function handleStarsEdit() {
    if (!stars) {
      setEditStars({
        powerShaping: {
          seated: 0,
          standing: 0,
        },
        powerRefining: {
          seated: 0,
          standing: 0,
        },
        powerSharpening: {
          seated: 0,
          standing: 0,
        },
        focusShaping: {
          seated: 0,
          standing: 0,
        },
        focusRefining: {
          seated: 0,
          standing: 0,
        },
        focusSharpening: {
          seated: 0,
          standing: 0,
        },
        CzShaping: {
          count: 0,
        },
        T3Shaping: {
          count: 0,
        },
        T4Shaping: {
          count: 0,
        },
        CzRefining: {
          count: 0,
        },
        T3Refining: {
          count: 0,
        },
        T4Refining: {
          count: 0,
        },
        CzSharpening: {
          count: 0,
        },
        T3Sharpening: {
          count: 0,
        },
        T4Sharpening: {
          count: 0,
        },
      });
    } else {
      setEditStars(stars);
    }
    setEditDialogOpen(true);
  }

  function handleDialogClose() {
    setEditDialogOpen(false);
  }

  async function handleUpdateStars(stars) {
    setProcessing(true);
    try {
      await request(updateStars(auth, userId, stars));
      setEditDialogOpen(false);
      refreshStars();
      setSnackbar('success', 'The stars information was updated successfully.');
    } catch (err) {
      console.log(err);
      setSnackbar('error', 'An error occurred while attempting to update the stars information.');
    }
    setProcessing(false);
  }

  useEffect(() => {
    if (userError || starsError) {
      if (loading) {
        setLoading(false);
      }
      const error = userError || starsError;
      setSnackbar('error', error.error);
    }
  }, [userError, starsError]);

  return (
    <Box>
      <Box
        display="flex"
        flexDirection={'row'}
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h3" component="h3">
          {`User: ${(user && user.firstName) || ''} ${(user && user.lastName) || ''}`}
        </Typography>
      </Box>
      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
        <Typography variant="h4" component="h4">
          Stars
        </Typography>
        {!stars &&
          <IconButton size="large" color="primary" onClick={handleStarsEdit}>
            <AddIcon />
          </IconButton>
        }
      </Stack>
      {stars &&
        (
          <StarsCard
            stars={stars}
            resilience={user?.resilience}
            neuro={user?.neuro}
            processing={processing}
            handleEdit={handleStarsEdit}
          />
        )
      }
      <br />
      {!stars && loading && (
        <CircularProgress color="primary" />
      )}
      <Dialog
        open={editDialogOpen}
        onClose={handleDialogClose}
        fullWidth
      >
        <DialogTitle>Stars Information</DialogTitle>
        <DialogContent>
          <StarsEdit
            stars={editStars}
            resilience={user?.resilience}
            neuro={user?.neuro}
            processing={processing}
            handleUpdate={handleUpdateStars}
            handleCancel={handleDialogClose}
            auth={auth}
            setSnackbar={setSnackbar}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
