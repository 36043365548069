import { useState } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import Button from "@mui/material/Button";
import validator from "../../../common/validations.mjs";

export default function editGroup({ group, processing, handleUpdate, handleCancel }) {
  const [description, setDescription] = useState(group.description);
  const [descriptionError, setDescriptionError] = useState(false);

  function validate() {
    let valid = true;
    if (!validator().isDescription(description)) {
      setDescriptionError(true);
      valid = false;
    } else {
      setDescriptionError(false);
    }
    if (valid) {
      handleUpdate({
        id: group.id,
        name: group.name,
        description: description,
      });
    }
  }

  function resetForm() {
    setDescription(group.description);
    if (handleCancel) {
      handleCancel();
    }
  }

  return (
    <Box component="form" noValidate>
      <div>
        <FormControl
          fullWidth
          margin="normal"
          variant="outlined"
          error={descriptionError}
        >
          <InputLabel htmlFor="outlined-description">
            Description
          </InputLabel>
          <OutlinedInput
            id="outlined-description"
            type="text"
            value={description}
            label="Description"
            onChange={event => {
              setDescription(event.target.value);
            }}
          />
          <FormHelperText>
            {descriptionError && 'This description is invalid.'}
          </FormHelperText>
        </FormControl>
      </div>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <Button
          disabled={processing || null}
          variant="outlined"
          color="error"
          onClick={resetForm}
        >
          Cancel
        </Button>
        <Button
          disabled={processing || null}
          sx={{ml: 1}}
          variant="contained"
          onClick={validate}
        >
          Save
        </Button>
      </Box>
    </Box>
  )
}
