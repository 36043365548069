import { useMemo } from "react";
import { getGroup } from "../requests.mjs";
import useResult from "../response";

export default function useGetGroup(auth, groupName) {
  const request = useMemo(function() {
    return getGroup(auth, groupName);
  }, []);
  return useResult(request);
};
