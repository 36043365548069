import { useRouteError } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <Box id="error-page" component="div">
      <Typography variant="h3" component="h3">
        Sorry! An unexpected error occurred.
      </Typography>
      <Typography mt={2} variant="subtitle" component="p">
        Please try again and if the error persists, please contact support.
      </Typography>
    </Box>
  );
}
