import { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import validator from "../../common/validations.mjs";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

export default function requestPasswordReset({email, setEmail, handlePasswordReset}) {
  const [emailError, setEmailError] = useState(false);

  function validate(event) {
    event.preventDefault();
    if (!validator().isEmail(email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
      handlePasswordReset();
    }
  }
  return (
    <Box
      component="form"
      noValidate
      onSubmit={validate}
    >
      <div>
        <TextField
          value={email}
          onChange={event => {
            setEmail(event.target.value);
          }}
          fullWidth
          error={emailError}
          helperText={emailError && 'This email address is not valid.'}
          margin="normal"
          label="Email"
          autoFocus
        />
      </div>
      <Box
        display="flex"
        justifyContent="flex-end"
        alignItems="flex-end"
      >
        <Button
          variant="outlined"
          color="error"
          LinkComponent={RouterLink}
          to="/"
        >
          Cancel
        </Button>
        <Button
          sx={{ml: 1}}
          variant="contained"
          type="submit"
        >
          Request Reset Code
        </Button>
      </Box>
    </Box>
  );
}
